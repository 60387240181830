// Color utility functions
import { formationPositions } from "../../../global/lib/utils/formationPositions";
import useLineupStore from "../../lib/store/LineupStore";
import { useState } from "react";

function getPositionBaseColor(positions) {
  if (positions.includes("KEYD")) return "bg-indigo-400 dark:bg-indigo-500";
  if (positions.includes("DEF")) return "bg-purple-400 dark:bg-purple-400";
  if (positions.includes("MID")) return "bg-emerald-400 dark:bg-emerald-500";
  if (positions.includes("RUC")) return "bg-orange-400 dark:bg-amber-600";
  if (positions.includes("FWD")) return "bg-sky-300 dark:bg-sky-500";
  if (positions.includes("KEYF")) return "bg-blue-400 dark:bg-blue-500";
  return "bg-gray-300 dark:bg-gray-600";
}

export function getPositionColors(currentPos) {
  const formationPos = formationPositions.find(
    (pos) => pos.abbr === currentPos,
  );
  if (!formationPos) return ["bg-gray-300 dark:bg-gray-600"];

  if (formationPos.isBonus || formationPos.abbr === "BN") {
    return ["bg-slate-300 dark:bg-slate-500"];
  }

  const eligibleColors = new Set(
    formationPos.eligiblePlayerPos.map(getPositionBaseColor),
  );

  const colors = Array.from(eligibleColors);

  // If more than 2 positions, return a multi-position color
  if (colors.length > 2) {
    return ["bg-pink-400 dark:bg-pink-600"];
  }

  return colors;
}

// Position type utility
function getPositionType(currentPos, isEmg) {
  if (isEmg) return "emg";
  switch (currentPos) {
    case "BN":
      return "bench";
    case "IL":
      return "injury";
    case "RL":
      return "rookie";
    case "VL":
      return "veteran";
    default:
      return "starter";
  }
}

// Swap players utility
function getSwapPlayers(formation, player, players) {
  const swapStarters = [];
  const swapEmgs = [];

  // Check starters
  formation.starters.forEach((startingPosition) => {
    const startingAbbr = startingPosition.abbr;
    if (startingAbbr === player.selectedPos && player.type === "starter")
      return;

    const canPlay = player.playerPos.some((playerPos) => {
      const formationPos = formationPositions.find(
        (fp) => fp.abbr === startingAbbr,
      );
      return formationPos?.eligiblePlayerPos.includes(playerPos);
    });

    if (canPlay) {
      players.forEach((swapPlayer) => {
        if (
          swapPlayer.id !== player.id &&
          swapPlayer.selectedPos === startingAbbr &&
          swapPlayer.type === "starter" &&
          !swapStarters.includes(swapPlayer)
        ) {
          swapStarters.push(swapPlayer);
        }
      });
    }
  });

  // Check emgs
  formation.emgs.forEach((emgPosition) => {
    const emgAbbr = emgPosition.abbr;
    if (emgAbbr === player.selectedPos && player.type === "emg") return;

    const canPlay = player.playerPos.some((playerPos) => {
      const formationPos = formationPositions.find((fp) => fp.abbr === emgAbbr);
      return formationPos?.eligiblePlayerPos.includes(playerPos);
    });

    if (canPlay) {
      players.forEach((swapPlayer) => {
        if (
          swapPlayer.id !== player.id &&
          swapPlayer.selectedPos === emgAbbr &&
          swapPlayer.type === "emg" &&
          !swapEmgs.includes(swapPlayer)
        ) {
          swapEmgs.push(swapPlayer);
        }
      });
    }
  });

  return { swapStarters, swapEmgs };
}

// Custom hook for lineup button logic
export function useLineupButton(currentPos, isEmg, listOrder, playerId) {
  const [isPressed, setIsPressed] = useState(false);
  const [
    players,
    bonusEligible,
    formation,
    dropPlayerIds,
    setDropPlayerIds,
    dropPositionAbbrs,
    setDropPositionAbbrs,
    selectedPlayerId,
    setSelectedPlayerId,
    selectedSwapPlayerId,
    setSelectedSwapPlayerId,
    setSelectedSwapPositionAbbr,
    setSelectedSwapPositionType,
    setSelectedSwapListOrder,
    isSwapping,
    setIsSwapping,
  ] = useLineupStore((state) => [
    state.players,
    state.bonusEligible,
    state.formation,
    state.dropPlayerIds,
    state.setDropPlayerIds,
    state.dropPositionAbbrs,
    state.setDropPositionAbbrs,
    state.selectedPlayerId,
    state.setSelectedPlayerId,
    state.selectedSwapPlayerId,
    state.setSelectedSwapPlayerId,
    state.setSelectedSwapPositionAbbr,
    state.setSelectedSwapPositionType,
    state.setSelectedSwapListOrder,
    state.isSwapping,
    state.setIsSwapping,
  ]);

  const player = players.find((p) => p.id === playerId);
  const isSelectedPlayer = player && selectedPlayerId === player.id;
  const isDropTarget =
    dropPositionAbbrs.includes(currentPos) ||
    dropPlayerIds.includes(player?.id);
  const disabled =
    isSwapping ||
    (!isDropTarget && selectedPlayerId && !isSelectedPlayer) ||
    (!playerId && !isDropTarget);

  function calculateDropPositions() {
    const basePositionsAbbrs = formationPositions
      .filter(
        (formationPos) =>
          !formationPos.isBonus &&
          player.playerPos.some((p) =>
            formationPos.eligiblePlayerPos.includes(p),
          ),
      )
      .map((formationPos) => formationPos.abbr);

    const bonusPositionsAbbrs = [
      ...(bonusEligible.injury.includes(player.id) ? ["IL"] : []),
      ...(bonusEligible.rookie.includes(player.id) ? ["RL"] : []),
      ...(bonusEligible.vet.includes(player.id) ? ["VL"] : []),
    ];

    return [...basePositionsAbbrs, ...bonusPositionsAbbrs];
  }

  function resetSelection() {
    setSelectedPlayerId(null);
    setDropPlayerIds([]);
    setDropPositionAbbrs([]);
  }

  function handleSwap() {
    setIsSwapping(true);
    setIsPressed(true);
    setSelectedSwapPositionAbbr(currentPos);
    setSelectedSwapPositionType(getPositionType(currentPos, isEmg));
    setSelectedSwapListOrder(listOrder);

    if (player) {
      setSelectedSwapPlayerId(player.id);
    }

    dispatchLineupEvent();
  }

  function handleInitialSelection() {
    if (!player) return;

    setSelectedPlayerId(player.id);
    const dropPositions = calculateDropPositions();
    const swapPlayers = getSwapPlayers(formation, player, players);

    setDropPlayerIds(
      [...swapPlayers.swapStarters, ...swapPlayers.swapEmgs].map(
        (player) => player.id,
      ),
    );
    setDropPositionAbbrs(dropPositions);
  }

  function handleClick() {
    if (selectedPlayerId) {
      if (isSelectedPlayer) {
        resetSelection();
      } else if (isDropTarget) {
        handleSwap();
      }
    } else {
      handleInitialSelection();
    }
  }

  return {
    isSwapping,
    isSelectedPlayer,
    isDropTarget,
    disabled,
    handleClick,
    isPressed,
  };
}

export function dispatchLineupEvent() {
  const lineupEvent = new CustomEvent("lineupEvent", {
    detail: { message: "Submit lineup" },
  });
  document
    .querySelector('[data-controller="lineup"]')
    .dispatchEvent(lineupEvent);
}
