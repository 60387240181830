import useDraftStore from "../lib/store/DraftStore";
import React, { useEffect } from "react";
import { PlayersTab } from "./PlayersTab";
import { QueueTab } from "./QueueTab";
import { ResultsTab } from "./ResultsTab";

export function TabBar() {
  const [activeTab, setActiveTab, queueIds] = useDraftStore((state) => [
    state.selectedTab,
    state.setSelectedTab,
    state.queueIds,
  ]);

  const queueLength = queueIds.length;

  return (
    <div className="h-11 border-b bg-white text-center font-semibold text-md text-gray-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400">
      <ul className="no-scrollbar flex max-w-[100vw] overflow-x-auto whitespace-nowrap">
        <TabButton
          label="Players"
          tab="players"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabButton
          label="Queue"
          tab="queue"
          badge={queueLength}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabButton
          label="Results"
          tab="results"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
    </div>
  );
}

function TabButton({ label, tab, activeTab, setActiveTab, badge = 0 }) {
  return (
    <li className="flex-1">
      <button
        onClick={() => {
          setActiveTab(tab);
          window.scrollTo({ top: 0, behavior: "instant" });
        }}
        className={`w-full uppercase inline-block px-6 py-3 ${
          activeTab === tab
            ? "border-primary-600 text-primary-600 dark:text-white"
            : "md:hover:text-primary-600 md:dark:hover:text-gray-300"
        }`}
      >
        {label}
        {badge > 0 && (
          <span className="ms-1.5 inline-flex items-center justify-center rounded-full bg-blue-500 text-white px-2 py-0.5 text-xs font-semibold">
            {badge}
          </span>
        )}
      </button>
    </li>
  );
}

export function TabContent() {
  const [activeTab] = useDraftStore((state) => [state.selectedTab]);

  useEffect(() => {
    const body = document.body;
    if (activeTab !== "players") {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }

    return () => body.classList.remove("overflow-hidden");
  }, [activeTab]);

  const renderContent = () => {
    switch (activeTab) {
      case "players":
        return <PlayersTab />;
      case "queue":
        return <QueueTab />;
      case "results":
        return <ResultsTab />;
      default:
        return <PlayersTab />;
    }
  };

  return renderContent();

  // return (
  //   <>
  //     <div className={`${activeTab === "players" ? "block" : "hidden"}`}>
  //       <PlayersTab />
  //     </div>
  //     <div className={`${activeTab === "queue" ? "block" : "hidden"}`}>
  //       <QueueTab />
  //     </div>
  //     <div className={`${activeTab === "results" ? "block" : "hidden"}`}>
  //       <ResultsTab />
  //     </div>
  //   </>
  // );
}
