import React, { useEffect, useRef, useState } from "react";
import useDraftStore from "../../lib/store/DraftStore";
import { HeaderContainer, TableHeader } from "./TableHeaders";
import { PlayerList } from "./PlayerList";
import { getTableColByKey } from "../../lib/utils/stats";
import { useViewport } from "../hooks/useViewport";

export const PlayerTable = ({ players }) => {
  const { sortConfig, setSortConfig, leagueData } = useDraftStore();
  const [sortedPlayers, setSortedPlayers] = useState([]);
  const containerRef = useRef(null);
  const mainTableRef = useRef(null);
  const headerRef = useRef(null);
  const isWide = useViewport();

  const tableSchema = generateTableSchema({ leagueData });

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "desc"
        ? "asc"
        : "desc";
    setSortConfig({ key, direction });

    if (!isWide) {
      window.scrollTo({ top: 128, behavior: "smooth" });
    }

    const sorted = [...players].sort((a, b) => {
      const aValue = getSortValue(a, key);
      const bValue = getSortValue(b, key);

      if (aValue === null && bValue === null) return 0;
      if (aValue === null) return 1;
      if (bValue === null) return -1;

      const comparison = bValue - aValue;
      return direction === "desc" ? comparison : -comparison;
    });

    setSortedPlayers(sorted);
  };

  const getSortValue = (player, path) => {
    const parts = path.split(".");
    const firstPart = parts[0];

    if (player[firstPart] === null) return null;

    let value = player;
    for (const part of parts) {
      value = value?.[part];
      if (value === null || value === undefined) return null;
    }

    if (path === "splits.avgGoals") {
      return value * 6 + player.splits.avgBehinds;
    }
    return value;
  };

  useEffect(() => {
    const sorted = [...players].sort((a, b) => {
      const aValue = getSortValue(a, sortConfig.key);
      const bValue = getSortValue(b, sortConfig.key);

      if (aValue === null && bValue === null) return 0;
      if (aValue === null) return 1;
      if (bValue === null) return -1;

      const comparison = bValue - aValue;
      return sortConfig.direction === "desc" ? comparison : -comparison;
    });
    setSortedPlayers(sorted);
  }, [players, sortConfig.key, sortConfig.direction]);

  const [isHeadersSynced, setIsHeadersSynced] = useState(false);
  const syncHeaderWidths = () => {
    const mainTable = mainTableRef.current;
    const tableHeader = headerRef.current?.querySelector("#tableHeader");

    if (!mainTable || !tableHeader) return;

    const tbody = mainTable.querySelector("tbody");
    if (!tbody || tbody.classList.contains("no-players")) return;

    requestAnimationFrame(() => {
      const contentCells = tbody
        .querySelector("tr:first-child")
        ?.querySelectorAll("td");
      const headerCells = tableHeader.querySelectorAll("th");

      if (contentCells && headerCells) {
        contentCells.forEach((cell, index) => {
          if (headerCells[index]) {
            const width = cell.offsetWidth;
            headerCells[index].style.width = `${width}px`;
            headerCells[index].style.minWidth = `${width}px`;
          }
        });
      }

      setTimeout(() => setIsHeadersSynced(true), 10);
    });
  };

  useEffect(() => {
    const tableContainer = containerRef.current;
    const tableHeader = headerRef.current?.querySelector("#tableHeader");

    if (!tableContainer || !tableHeader) return;

    const resizeObserver = new ResizeObserver(syncHeaderWidths);
    resizeObserver.observe(mainTableRef.current);

    const handleScroll = () => {
      const tableHeader = headerRef.current?.querySelector("#tableHeader");
      if (!tableHeader) return;
      const scrollLeft = containerRef.current.scrollLeft;
      tableHeader.style.transform = `translateX(${-scrollLeft}px)`;
    };

    tableContainer.addEventListener("scroll", handleScroll);
    setTimeout(syncHeaderWidths, 100);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
      resizeObserver.disconnect();
    };
  }, [sortedPlayers]);

  const getStatValue = (player, path) => {
    const parts = path.split(".");
    let value = player;
    for (const part of parts) {
      value = value?.[part];
      if (value === null || value === undefined) {
        return "-";
      }
    }

    if (path === "splits.avgGoals") {
      return `${value.toFixed(1)}-${player.splits.avgBehinds.toFixed(1)} (${(
        value * 6 +
        player.splits.avgBehinds
      ).toFixed(1)})`;
    }

    if (
      path.startsWith("ranks.") ||
      path.startsWith("career.") ||
      path === "splits.games"
    ) {
      return value;
    }

    return typeof value === "number" ? value.toFixed(1) : value;
  };

  return !players ? null : (
    <div id="player-table" className={!isHeadersSynced ? "hidden" : ""}>
      <HeaderContainer
        columnGroups={tableSchema.columnGroups}
        currentSort={sortConfig.key}
        headerRef={headerRef}
        onSort={handleSort}
      />

      <div id="tableContainer">
        <div
          className="no-scrollbar -mx-2 overflow-scroll overscroll-x-none bg-white text-xs md:mx-0 md:overflow-visible dark:bg-gray-900 dark:text-gray-200 dark:shadow-none"
          ref={containerRef}
        >
          <div className="flex">
            <PlayerList players={sortedPlayers} />

            <table
              id="mainTableContent"
              ref={mainTableRef}
              className="w-full grow border-separate border-spacing-0 md:table-auto"
            >
              <TableHeader
                columnGroups={tableSchema.columnGroups}
                currentSort={sortConfig.key}
                onSort={handleSort}
              />
              <tbody>
                {sortedPlayers.map((player) => (
                  <tr key={player.id} className="bg-white dark:bg-inherit">
                    {tableSchema.columnGroups.map((group) => {
                      const numCols = group.columns.length;

                      return group.columns.map((column, colIndex) => (
                        <td
                          key={`${player.id}-${column.sortParam}`}
                          className={`h-[75px] whitespace-nowrap p-0 align-bottom font-medium md:h-12 md:min-w-0 md:align-middle md:font-normal ${
                            colIndex === 0
                              ? "md:border-l md:border-l-gray-100"
                              : ""
                          } border-b border-gray-200 text-center dark:border-gray-700 ${
                            sortConfig.key === column.sortParam
                              ? "font-semibold text-orange-500 md:bg-gray-50 dark:text-orange-400 md:dark:bg-gray-800"
                              : ""
                          }`}
                        >
                          <div
                            className={`border-t px-5 py-1 md:border-t-0 ${
                              numCols > 12 ? "md:px-1.5" : "md:px-3"
                            } dark:border-gray-700`}
                          >
                            {getStatValue(player, column.sortParam)}
                          </div>
                        </td>
                      ));
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const generateTableSchema = ({ leagueData }) => {
  if (leagueData.scoringType === "Points") {
    return {
      columnGroups: [
        {
          title: "Fantasy Points",
          columns: [
            {
              title: "Proj",
              mobileTitle: "Proj Avg.",
              sortParam: "points.proj",
              desc: "Projected Average",
            },
            {
              title: "Avg",
              mobileTitle: "'24 Avg'",
              sortParam: "points.average",
              desc: "Average Points",
            },
          ],
        },
        {
          title: "Career",
          columns: [
            {
              title: "Seasons",
              sortParam: "career.seasonsList",
              desc: "Seasons on List",
            },
            {
              title: "Games",
              sortParam: "career.gamesTotal",
              desc: "Career Games (including finals)",
            },
            {
              title: "Age",
              sortParam: "career.age",
              desc: "Player Age",
            },
          ],
        },
      ],
    };
  }

  return {
    columnGroups: [
      {
        title: "2024 Ranks",
        columns: [
          {
            title: "Tot.",
            sortParam: "ranks.total",
            desc: "Rank (Total stats)",
          },
          {
            title: "Avg.",
            sortParam: "ranks.average",
            desc: "Rank (Avg stats)",
          },
        ],
      },
      {
        title: "2024 Stats",
        columns: [
          { title: "GP", sortParam: "splits.games", desc: "Games Played" },
          ...leagueData.statCols.map((key) => {
            const col = getTableColByKey(key);
            return {
              title: col.field,
              sortParam: col.sortParam,
              desc: col.name,
            };
          }),
        ],
      },
    ],
  };
};

export default PlayerTable;
