import React, { useEffect } from "react";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import {
  handlePauseDraft,
  handleResetDraft,
  handleResumeDraft,
  handleStartDraft,
} from "../lib/utils/handlers";
import useDraftStore from "../lib/store/DraftStore";
import { IconReset } from "../../global/components/icons/IconReset";
import { IconPause } from "../../global/components/icons/IconPause";
import { IconPlaySquare } from "../../global/components/icons/IconPlaySquare";
import { IconLeaveDraft } from "../../global/components/icons/IconLeaveDraft";
import { IconAutopicking } from "./IconAutopicking";

export function UpcomingStrip() {
  const [
    picks,
    currentPickId,
    onTheClock,
    teamsById,
    userTeamId,
    draftStatus,
    leagueData,
    leagueSeasonId,
    boardId,
    autopicking,
  ] = useDraftStore((state) => [
    state.picks,
    state.currentPickId,
    state.onTheClock,
    state.teamsById,
    state.userTeamId,
    state.draftStatus,
    state.leagueData,
    state.leagueSeasonId,
    state.boardId,
    state.autopicking,
  ]);

  const currentPick =
    picks.find((pick) => pick.id === currentPickId) ||
    picks.find((pick) => pick.playerId === null);

  const availablePicks = picks.filter(
    (pick) =>
      (pick.overallPick > currentPick?.overallPick &&
        !pick.surplus &&
        !pick.playerId) ??
      9999,
  );

  const isCommish = leagueData?.isCommish ?? false;

  return draftStatus === "Complete" ? (
    <div
      className={`h-9 lg:h-auto flex w-full items-center bg-gray-800 overflow-x-auto overflow-y-hidden lg:overflow-x-visible lg:overflow-y-visible scrollbar-none`}
    >
      <div className="flex-1 grow text-center lg:text-left px-4 text-white font-medium text-xs">
        Draft Complete! Good luck for the season ahead
      </div>
      {isCommish && (
        <CommishButtons
          draftStatus={draftStatus}
          leagueSeasonId={leagueSeasonId}
          boardId={boardId}
        />
      )}
    </div>
  ) : (
    <div
      className={`h-9 lg:h-auto flex w-full items-center bg-gray-800 overflow-x-auto overflow-y-hidden lg:overflow-x-visible lg:overflow-y-visible scrollbar-none`}
    >
      <a
        href={`/afl/${leagueSeasonId}/`}
        className="text-gray-300 sticky top-0 left-0 h-9 lg:h-14  rounded-br-lg lg:rounded-l-2xl text-right gap-2 z-0 flex items-center justify-end pl-1.5 pr-1.5 mb-auto lg:hidden"
      >
        <IconLeaveDraft />
      </a>
      <div className="text-gray-300 bg-gray-800 sticky left-0 z-10 flex items-center pl-1.5 pr-1 h-full">
        <div className="text-xs font-medium">Next:</div>
        <div className="absolute inset-y-0 -right-3 w-3 bg-gradient-to-r from-gray-800 to-transparent" />
      </div>
      <div className="flex items-center pl-3 w-full gap-2 min-w-max pr-2">
        {availablePicks.map((pick, index) => {
          const showRound =
            index > 0 && availablePicks[index - 1]?.round !== pick.round;
          const team = teamsById[pick.teamId];
          const isAutopicking =
            Array.isArray(autopicking) && autopicking.includes(pick.teamId);

          return (
            <div key={pick.overallPick} className="flex items-center">
              {showRound && (
                <span className="mr-2.5 ml-1.5 text-white text-xs lg:text-base font-medium py-0.5 px-1.5 lg:px-1 border-y">
                  R{pick.round}
                </span>
              )}
              <div className="relative">
                {pick.teamId === userTeamId && (
                  <div className="absolute -top-2 left-1/2 -translate-x-1/2 w-0 h-0 border-l-4 border-r-4 border-t-4 border-transparent border-t-white" />
                )}
                <div className="relative">
                  {team.imgName ? (
                    <div
                      className={`size-5.5 ${pick.teamId === userTeamId ? "lg:size-8" : "lg:size-6 opacity-90"} rounded-full overflow-hidden`}
                    >
                      <img
                        src={team.imgPath}
                        alt={team.name}
                        className="w-full h-full object-cover"
                      />
                      {isAutopicking && (
                        <div className="absolute rounded-full inset-0 bg-black/50 flex items-center justify-center">
                          <IconAutopicking
                            className={`${pick.teamId === userTeamId ? "size-4" : "size-3"} text-white/90`}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className={`size-5.5 ${pick.teamId === userTeamId ? "lg:size-8" : "lg:size-6 opacity-90"} text-xxs flex items-center justify-center text-white text-opacity-90 rounded-full relative`}
                      style={{ backgroundColor: team.color }}
                    >
                      {isAutopicking ? (
                        <div className="absolute rounded-full inset-0 bg-black/50 flex items-center justify-center">
                          <IconAutopicking
                            className={`${pick.teamId === userTeamId ? "size-4" : "size-3"} text-white/90`}
                          />
                        </div>
                      ) : (
                        team.name.charAt(0).toUpperCase()
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {isCommish && (
        <CommishButtons
          draftStatus={draftStatus}
          leagueSeasonId={leagueSeasonId}
          boardId={boardId}
        />
      )}
    </div>
  );
}

function IconSettings() {
  return (
    <svg
      className="lg:size-5 size-4"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="M6 4v10m0 0a2 2 0 1 0 0 4m0-4a2 2 0 1 1 0 4m0 0v2m6-16v2m0 0a2 2 0 1 0 0 4m0-4a2 2 0 1 1 0 4m0 0v10m6-16v10m0 0a2 2 0 1 0 0 4m0-4a2 2 0 1 1 0 4m0 0v2"
      />
    </svg>
  );
}

function DraftButton({
  onClick,
  icon,
  className = "",
  href = null,
  tooltip = null,
}) {
  const buttonRef = React.useRef();
  const tippyInstance = React.useRef();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        // lg breakpoint
        if (tooltip && buttonRef.current && !tippyInstance.current) {
          tippyInstance.current = tippy(buttonRef.current, {
            content: tooltip,
            placement: "top",
            arrow: true,
            animation: "shift-away-subtle",
            interactive: false,
            allowHTML: true,
            delay: [50, 0],
            theme: "custom",
            onShow(instance) {
              instance.popper
                .querySelector(".tippy-box")
                .classList.add(
                  "bg-gray-800",
                  "text-white",
                  "dark:bg-gray-700",
                  "text-sm",
                );
              instance.popper
                .querySelector(".tippy-arrow")
                .classList.add("text-gray-800", "dark:text-gray-700");
            },
          });
        }
      } else if (tippyInstance.current) {
        tippyInstance.current.destroy();
        tippyInstance.current = null;
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (tippyInstance.current) {
        tippyInstance.current.destroy();
        tippyInstance.current = null;
      }
    };
  }, [tooltip]);

  if (href) {
    return (
      <a
        ref={buttonRef}
        href={href}
        data-turbo-frame="modal"
        data-turbo="true"
        className={`rounded-full bg-gray-700 p-1 lg:p-2 hover:text-white ${className}`}
      >
        {icon}
      </a>
    );
  }

  return (
    <button
      ref={buttonRef}
      onClick={onClick}
      className={`flex items-center justify-center ${className}`}
    >
      {icon}
    </button>
  );
}

export function CommishButtons({ draftStatus, leagueSeasonId, boardId }) {
  if (!draftStatus) return null;

  function getButtons() {
    const baseButtons = [
      {
        href: `/afl/${leagueSeasonId}/draft/board/${boardId}/settings`,
        icon: <IconSettings />,
        key: "settings",
        tooltip: "Adjust Settings",
      },
    ];

    switch (draftStatus) {
      case "InProgress":
        return [
          ...baseButtons,
          {
            onClick: handlePauseDraft,
            icon: <IconPause />,
            key: "pause",
            tooltip: "Pause Draft",
          },
        ];
      case "Scheduled":
      case "Unscheduled":
        return [
          ...baseButtons,
          {
            onClick: handleStartDraft,
            icon: <IconPlaySquare />,
            key: "start",
            tooltip: "Start Draft",
          },
        ];
      case "Paused":
        return [
          ...baseButtons,
          {
            onClick: handleResetDraft,
            icon: <IconReset />,
            key: "reset",
            tooltip: "Reset to Start",
          },
          {
            onClick: handleResumeDraft,
            icon: <IconPlaySquare />,
            key: "resume",
            tooltip: "Resume Draft",
          },
          // {
          //   onClick: handleCompleteDraft,
          //   icon: <IconFastForward />,
          //   key: "complete",
          //   tooltip: "Autopick To End",
          // },
        ];
      case "Complete":
        return [
          ...baseButtons,
          {
            onClick: handleResetDraft,
            icon: <IconReset />,
            key: "reset",
            tooltip: "Reset to Start",
          },
        ];
      default:
        return baseButtons;
    }
  }

  const buttons = getButtons();
  if (buttons.length === 0) return null;

  return (
    <div className="text-gray-300 sticky top-0 right-0 h-8 lg:h-14 bg-gray-600 rounded-l-lg lg:rounded-l-2xl text-right gap-2 z-0 flex items-center justify-end px-3 lg:my-2 my-0.5 lg:px-4">
      {buttons.map((button) => (
        <DraftButton
          key={button.key}
          onClick={button.onClick}
          icon={button.icon}
          href={button.href}
          className={button.className}
          tooltip={button.tooltip}
        />
      ))}
    </div>
  );
}
