import React, { useEffect, useState } from "react";
import { BaseDialog } from "../../../global/components/BaseDialog";

function PreselectionDialog({ modalTitle = "How to Play Gridley" }) {
  const [isOpen, setIsOpen] = useState(false); // Start with false, will check in useEffect

  useEffect(() => {
    const hasSeenDialog = localStorage.getItem("hasSeenDraftIntro");
    if (!hasSeenDialog) {
      setIsOpen(true);
    }
  }, []);

  function closeModal() {
    localStorage.setItem("hasSeenDraftIntro", "true");
    setIsOpen(false);
  }

  // Don't render anything if the dialog shouldn't be shown
  if (!isOpen) {
    return null;
  }

  return (
    <BaseDialog
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle=""
      modalBody={<ModalBody closeModal={closeModal} />}
      size="lg"
      showClose={false}
    />
  );
}

function ModalBody({ closeModal }) {
  return (
    <>
      <div className="text-md sm:text-base font-normal text-gray-700">
        <div className="-mx-4 -mt-6 mb-4 relative flex items-center bg-[url('/assets/images/keeper/modals/draftroom.png')] justify-center h-32 sm:h-48 bg-cover bg-center">
          <div className="absolute inset-0 dark:bg-black/30"></div>
        </div>
        <div className="md:px-2 py-2 dark:text-gray-300">
          <h3 className="mb-4 text-center text-xl font-semibold dark:text-gray-100 sm:text-2xl">
            Welcome to the Draft
          </h3>

          <div className="mb-4">
            Start ranking players and explore the draft room ahead of the big
            day.
          </div>

          <div className="mb-6">
            <span className="font-bold dark:text-white">Commissioners:</span>{" "}
            You'll be able to start, pause and resume drafts at any time, edit
            picks, and conduct a single computer draft when drafts open on Feb
            10th.
          </div>

          <div className="text-center">
            <button
              onClick={closeModal}
              className="mx-auto inline-block rounded-lg bg-primary-500 px-5 py-2 text-center font-medium text-white hover:bg-primary-600 disabled:opacity-50 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Lets Go →
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreselectionDialog;
