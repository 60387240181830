import React from "react";
import { getTeamById } from "../../../global/lib/utils/teams";

export default function KeeperCell({ player }) {
  const playerTeam = player ? getTeamById(player.teamId) : null;

  return (
    <div
      className={`mb-0.5 group relative rounded-md pl-2.5 pr-2 py-2 text-gray-700 dark:text-gray-400 bg-gray-200 dark:bg-gray-700`}
    >
      <span className="block truncate text-xs font-medium tracking-tight">
        <img
          className="mb-px mr-0.5 inline-block h-4 w-4 rounded-full border border-gray-200 border-opacity-70 dark:border-gray-500"
          src={`/build/images/sport/teams/logos/512/${player.teamId}.png`}
          alt={`${playerTeam.name} colours`}
        />
        {player.abbrName}
      </span>
      <span className="block truncate text-xxs font-medium tracking-tight opacity-70">
        {player.positions?.join("/")}
      </span>
    </div>
  );
}
