import React, { useEffect, useRef, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import useDraftStore from "../lib/store/DraftStore";
import { getTeamById } from "../../global/lib/utils/teams";
import { DraftButton } from "./buttons/DraftButton";

function DragHandle({ id, index, ...props }) {
  return (
    <button
      className="w-12 px-2 h-10 flex flex-col items-center justify-center gap-1 text-gray-400 cursor-grab active:cursor-grabbing touch-none"
      {...props}
    >
      <div className="flex flex-col gap-1">
        <div className="w-4 h-0.5 bg-current"></div>
        <div className="w-4 h-0.5 bg-current"></div>
        <div className="w-4 h-0.5 bg-current"></div>
      </div>
      <span className="sr-only">Drag item {index + 1}</span>
    </button>
  );
}

function SortablePlayerCard({ player, index }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: player.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    player && (
      <div
        ref={setNodeRef}
        style={style}
        className="mb-2 bg-white dark:bg-gray-800 rounded-lg shadow select-none"
      >
        <div className="flex items-center">
          <div className="w-10 h-10 flex items-center justify-center text-sm font-semibold text-gray-500 dark:text-gray-400">
            {index + 1}
          </div>
          <QueuePlayerCard player={player} />
          <DragHandle
            id={player.id}
            index={index}
            {...attributes}
            {...listeners}
          />
        </div>
      </div>
    )
  );
}

export function QueueTab() {
  const { players, picks, queueIds, setQueueIds } = useDraftStore();
  const [activeId, setActiveId] = useState(null);
  const activeIdRef = useRef(null);

  // Keep activeIdRef in sync with activeId
  useEffect(() => {
    activeIdRef.current = activeId;
  }, [activeId]);

  // Monitor queueIds changes and cancel drag if needed
  useEffect(() => {
    if (activeIdRef.current && !queueIds.includes(activeIdRef.current)) {
      // Player being dragged was removed from queue - cancel the drag
      document.dispatchEvent(new KeyboardEvent("keydown", { key: "Escape" }));
    }
  }, [queueIds]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const queuePlayers = players
    .filter((player) => queueIds.includes(player.id))
    .sort((a, b) => queueIds.indexOf(a.id) - queueIds.indexOf(b.id));

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over?.id && queueIds.includes(active.id)) {
      const oldIndex = queueIds.indexOf(active.id);
      const newIndex = queueIds.indexOf(over.id);

      const newQueueIds = [...queueIds];
      newQueueIds.splice(oldIndex, 1);
      newQueueIds.splice(newIndex, 0, active.id);

      setQueueIds(newQueueIds);
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  if (queuePlayers.length === 0) {
    return (
      <div className="flex items-center justify-center p-8 text-gray-500 dark:text-gray-400">
        No players in queue
      </div>
    );
  }

  const overlayPlayer = queuePlayers.find((p) => p.id === activeId);

  return (
    <div className="h-[calc(100vh-9.5rem)] lg:h-full overflow-y-auto">
      <div className="px-2 pt-4 pb-24 md:pb-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={queuePlayers.map((p) => p.id)}
            strategy={verticalListSortingStrategy}
          >
            {queuePlayers.map((player, index) => (
              <SortablePlayerCard
                key={player.id}
                player={player}
                index={index}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {activeId && overlayPlayer ? (
              <div className="mb-2 bg-white dark:bg-gray-800 rounded-lg shadow">
                <div className="flex items-center">
                  <div className="w-10 h-10 flex items-center justify-center text-sm font-semibold text-gray-500 dark:text-gray-400">
                    {queuePlayers.findIndex((p) => p.id === activeId) + 1}
                  </div>
                  <QueuePlayerCard player={overlayPlayer} />
                  <DragHandle
                    id={activeId}
                    index={queuePlayers.findIndex((p) => p.id === activeId)}
                  />
                </div>
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    </div>
  );
}

export function QueuePlayerCard({ player }) {
  const { queueIds, setQueueIds, leagueSeasonId, leagueData, draftStatus } =
    useDraftStore();
  const team = getTeamById(player.teamId);
  const isQueued = queueIds.includes(player.id);
  const isCategories = leagueData.scoringType === "Categories";

  const toggleQueue = () => {
    if (isQueued) {
      setQueueIds(queueIds.filter((id) => id !== player.id));
    } else {
      setQueueIds([...queueIds, player.id]);
    }
  };

  return (
    <>
      <div className="h-12 overflow-hidden min-w-0 grow py-1.5 leading-tight">
        <div className="flex items-center justify-start">
          <button onClick={toggleQueue} className="mr-4 pointer-events-auto">
            <img
              className="inline-block h-7 w-7"
              src={`/build/images/keeper/icons/star-${isQueued ? "filled" : "outlined"}.png`}
              alt={isQueued ? "Remove from Queue" : "Add to Queue"}
            />
          </button>
          <div className="overflow relative -mb-2.5 mr-2.5 flex h-10 w-8 items-start justify-center overflow-clip dark:brightness-125 dark:saturate-[0.8]">
            <img
              className="w-full"
              src={`/build/images/sport/teams/guernseys/${team.abbr}.png`}
              alt={`${team.name} logo`}
            />
          </div>
          <span className="block min-w-0 flex-1 text-left leading-tight">
            <span className="mr-1 flex items-center justify-start whitespace-nowrap text-md font-medium leading-tight dark:text-gray-100">
              <a
                className="pointer-events-auto truncate sm:hover:underline"
                data-turbo-frame="modal"
                href={`/afl/${leagueSeasonId}/players/profile/${player.id}`}
              >
                {player.abbrName}
              </a>
              <span className="ml-1 text-xs font-medium text-gray-800 dark:text-gray-200">
                {player.positions.join("/")}
              </span>
              {player.injuryStatus !== "0" && (
                <span
                  data-tooltip={player.injuryDesc}
                  className="ml-1.5 inline-flex items-center justify-center rounded-full bg-red-600 px-1 pb-0.75 pt-1 text-xxxxs font-semibold uppercase leading-none tracking-tight text-white dark:bg-red-700"
                >
                  {player.injuryAbbr}
                </span>
              )}
            </span>
            <span className="block min-w-0 flex-1">
              <span className="flex items-center justify-start pb-px pt-1.5 md:truncate md:pt-1">
                <span className="inline-flex items-center justify-center text-xs font-normal leading-none tracking-tight text-gray-700 sm:font-normal dark:text-gray-400">
                  {isCategories
                    ? "ADP: " + (player.adp || "-")
                    : "Proj: " + (player.points.proj?.toFixed(1) || "-")}
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      {!draftStatus.includes("cheduled") && (
        <div className="flex h-12 items-center justify-center md:mr-2 md:-pl-2 md:pr-0">
          <DraftButton playerId={player.id} playerName={player.name} />
        </div>
      )}
    </>
  );
}
