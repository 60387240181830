import React from "react";
import { getTeamById } from "../../../global/lib/utils/teams";
import useDraftStore from "../../lib/store/DraftStore";

function PickCell({ pick, player, isCommish = false, leagueSeasonId }) {
  const [currentPickId, teamsById] = useDraftStore((state) => [
    state.currentPickId,
    state.teamsById,
  ]);
  const isSurplusPick = pick.surplus;
  const isTraded = pick.teamId !== pick.originalTeamId;
  const playerTeam = player ? getTeamById(player.teamId) : null;
  const onTheClock = currentPickId === pick.id && pick.playerId === null;
  function getBgClass() {
    if (onTheClock) return "dark bg-yellow-200 dark:bg-amber-300 animate-pulse";

    if (!player) {
      return isSurplusPick
        ? "bg-gray-100 dark:bg-gray-800"
        : "bg-gray-200 dark:bg-gray-700";
    }

    const positions = player.positions;

    if (positions.includes("RUC")) return "bg-orange-400 dark:bg-amber-500";
    if (positions.includes("KEYD")) return "bg-purple-400 dark:bg-purple-500";
    if (positions.includes("KEYF")) return "bg-sky-400 dark:bg-sky-500";
    if (positions.includes("FWD")) return "bg-sky-300 dark:bg-sky-400";
    if (positions.includes("DEF")) return "bg-purple-300 dark:bg-purple-400";
    if (positions.includes("MID")) return "bg-emerald-300 dark:bg-emerald-400";

    return "bg-teal-200 dark:bg-primary-800";
  }

  return (
    <div
      className={`group relative mb-0.5 min-h-14 rounded-md pl-2.5 pr-7 pt-2 ${getBgClass()}`}
    >
      <span
        className={`absolute right-1 top-2 text-xs ${
          player
            ? "text-gray-800/70 dark:text-gray-600"
            : "text-gray-400 dark:text-gray-600"
        } ${isSurplusPick ? "line-through" : ""}`}
      >
        {`${pick.round}.${pick.roundPick}`}
      </span>

      {player ? (
        <>
          <span className="block truncate text-xs font-semibold tracking-tight">
            <img
              className="mb-px mr-0.5 inline-block h-4 w-4 rounded-full border border-gray-200 border-opacity-70 dark:border-gray-500"
              src={`/build/images/sport/teams/logos/512/${player.teamId}.png`}
              alt={`${playerTeam.name} colours`}
            />
            {player.abbrName}
          </span>
          {!isTraded && (
            <span className="block truncate text-xxs font-medium tracking-tight opacity-70">
              {player.positions?.join("/")}
            </span>
          )}
        </>
      ) : isSurplusPick ? (
        <span className="block items-center justify-start truncate text-xxs text-gray-400 dark:text-gray-600">
          Team Full
        </span>
      ) : onTheClock ? (
        <span className="block items-center justify-start font-semibold tracking-tight truncate text-xs text-opacity-90">
          On The Clock
        </span>
      ) : (
        <span className="block items-center justify-start truncate text-xs dark:text-gray-400">
          Pick {pick.overallPick}
        </span>
      )}

      {isTraded && (
        <span
          className={`${isSurplusPick ? "opacity-50" : ""} absolute bottom-0 left-0 right-0 flex items-center justify-start truncate rounded-b-md bg-gray-700/60 text-white p-1 text-xxs font-medium tracking-tight dark:bg-gray-600/80 dark:text-white`}
        >
          <svg
            className="mr-1 h-4 w-4 text-white"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.707,17.707a1,1,0,0,1-1.414-1.414L19.586,13H2a1,1,0,0,1,0-2H19.586L16.293,7.707a1,1,0,0,1,1.414-1.414l5,5a1,1,0,0,1,0,1.414Z" />
          </svg>
          <span className="truncate">{teamsById[pick.teamId].name}</span>
        </span>
      )}

      {isCommish && (
        <a
          data-turbo="true"
          data-turbo-frame="modal"
          href={`/afl/${leagueSeasonId}/draft/pick/transfer/${pick.id}`}
          className="absolute inset-0 hidden items-center justify-center truncate rounded-md bg-black/50 text-xxs font-medium tracking-tight text-white group-hover:flex dark:bg-gray-600/50"
        >
          <span className="flex items-center justify-center">Update</span>
        </a>
      )}
    </div>
  );
}

export default PickCell;
