import useDraftStore from "../store/DraftStore";

const BASE_URL = "/api/v1/draft";

const handleDraftAction = async (action, confirmMessage) => {
  if (!confirm(confirmMessage)) return;

  try {
    const response = await fetch(
      `${BASE_URL}/${action}/${useDraftStore.getState().boardId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      },
    );

    if (!response.ok) {
      const data = await response.json().catch(() => null);
      const errorMessage = data?.error || `HTTP error: ${response.status}`;
      alert(errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error(`Failed to ${action} draft:`, error);
  }
};

export const handleStartDraft = () =>
  handleDraftAction("start", "Do you want to start the draft immediately?");

export const handlePauseDraft = () =>
  handleDraftAction("pause", "Do you want to pause the draft?");

export const handleResumeDraft = () =>
  handleDraftAction("resume", "Do you want to resume the draft?");

export const handleResetDraft = () =>
  handleDraftAction(
    "reset",
    "Do you want to reset to a pre-draft state? Note: all current draft selections WILL BE LOST.",
  );

export const handleCompleteDraft = () =>
  handleDraftAction(
    "complete",
    "Do you want to complete the draft by autodrafting the remaining picks?",
  );
