import React from "react";
import { getPositionColors, useLineupButton } from "./LineupButtonUtils";
import useLineupStore from "../../lib/store/LineupStore";

function LineupButton({ currentPos, isEmg, listOrder, playerId }) {
  const {
    isSwapping,
    isSelectedPlayer,
    isDropTarget,
    disabled,
    handleClick,
    isPressed,
  } = useLineupButton(currentPos, isEmg, listOrder, playerId);

  const players = useLineupStore((state) => state.players);

  // Get the current player's name
  const playerName = players.find((p) => p.id === playerId)?.name || "Empty";

  const colors = getPositionColors(currentPos);

  const baseClasses = `
    group pointer-events-auto
    relative rounded-xl w-12 h-8
    flex items-center justify-center 
    font-semibold text-xxs uppercase
    transition-all duration-150
    ${disabled && !isPressed ? "opacity-60 cursor-not-allowed" : "cursor-pointer"}
    ${isSelectedPlayer ? "sm:scale-105 animate-pulse dark:brightness-110" : "sm:hover:scale-105"}
    ${isDropTarget && !isSelectedPlayer ? "dark:brightness-125 brightness-110" : ""}
  `.trim();

  function renderColorPanels() {
    if (colors.length === 0) return null;

    return colors.map((color, index) => {
      const isFirst = index === 0;
      const isLast = index === colors.length - 1;
      let position;
      if (colors.length === 1) {
        position = "inset-0";
      } else if (colors.length === 2) {
        position = isFirst ? "left-0 right-1/2" : "left-1/2 right-0";
      }

      const baseColorClass = `
        absolute top-0 bottom-0
        transition-colors duration-150
        sm:group-hover:brightness-110
        ${color}
        ${position}
        ${isFirst ? "rounded-l-xl" : ""}
        ${isLast ? "rounded-r-xl" : ""}
        ${colors.length === 1 ? "rounded-xl" : ""}
        ${disabled && !isPressed ? "opacity-60" : ""}
        ${isSelectedPlayer ? "brightness-110" : ""}
      `.trim();

      return <div key={index} className={baseColorClass} aria-hidden="true" />;
    });
  }

  // Function to get the full position name
  function getFullPositionName(pos) {
    const specialPositions = {
      BN: "Bench",
      IL: "Injury List",
      RL: "Rookie List",
      VL: "Veteran List",
    };

    if (pos in specialPositions) {
      return `${specialPositions[pos]} ${listOrder}`;
    }

    const positionType = isEmg ? "Emergency" : "Starting";
    return `${positionType} ${pos} ${listOrder}`;
  }

  // Determine the appropriate aria-label based on the button's state
  const fullPosition = getFullPositionName(currentPos);

  let ariaLabel;
  if (isSelectedPlayer) {
    ariaLabel = `Selected: ${playerName} in ${fullPosition}`;
  } else if (isDropTarget) {
    ariaLabel = `Move to ${fullPosition}, replace ${playerName}`;
  } else {
    ariaLabel = `Select ${playerName} from ${fullPosition}`;
  }

  return (
    <button
      onClick={handleClick}
      data-droptarget={isDropTarget}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-pressed={isSelectedPlayer}
      aria-busy={isSwapping && isPressed}
      className={baseClasses}
    >
      {renderColorPanels()}

      {isSelectedPlayer && !isPressed && (
        <div
          className="absolute inset-0 rounded-xl bg-black/10 dark:bg-white/10"
          aria-hidden="true"
        />
      )}

      {!isSelectedPlayer && isDropTarget && (
        <div
          className="absolute -inset-px rounded-xl border border-dashed border-black/70 dark:border-white/70"
          aria-hidden="true"
        />
      )}

      {isSwapping && isPressed ? (
        <svg
          className="h-4 w-4 animate-spin relative z-10"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
            fill="none"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : (
        <span className="relative text-black/90 z-10">{currentPos}</span>
      )}
    </button>
  );
}

export default LineupButton;
