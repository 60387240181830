import React, { useEffect, useState } from "react";
import useDraftStore from "../lib/store/DraftStore";
import { ordinal } from "../../global/lib/utils/numberFunctions";
import { IconAutopicking } from "./IconAutopicking";

function Spinner() {
  return (
    <div className="animate-spin h-6 w-6 ml-auto border-4 border-gray-300 dark:border-gray-600 border-t-green-500 dark:border-t-amber-500 rounded-full" />
  );
}

export function PreDraftCountdown({ draftTime, draftStatus }) {
  const [timeDisplay, setTimeDisplay] = useState("");

  useEffect(() => {
    function calculateTimeLeft() {
      const now = new Date();
      const draftDate = new Date(draftTime);
      const diff = draftDate.getTime() - now.getTime();
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      if (days > 0) return `${days} ${days === 1 ? "day" : "days"}`;
      if (hours > 0) return `${hours} ${hours === 1 ? "hour" : "hours"}`;
      return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }

    const timer = setInterval(() => {
      setTimeDisplay(calculateTimeLeft());
    }, 1000);

    setTimeDisplay(calculateTimeLeft());
    return () => clearInterval(timer);
  }, [draftTime]);

  return (
    <div className="text-lg leading-tight font-bold dark:text-gray-200 text-gray-700 mt-1 shrink-0 w-20 text-right">
      {draftStatus === "Unscheduled" ? "TBC" : timeDisplay}
    </div>
  );
}

export function OnTheClock() {
  const [
    picks,
    currentPickId,
    onTheClock,
    boardData,
    teamsById,
    pickExpiresAt,
    draftStatus,
    leagueData,
    autopicking,
  ] = useDraftStore((state) => [
    state.picks,
    state.currentPickId,
    state.onTheClock,
    state.boardData,
    state.teamsById,
    state.pickExpiresAt,
    state.draftStatus,
    state.leagueData,
    state.autopicking,
  ]);

  const currentPick =
    picks.find((pick) => pick.id === currentPickId) || picks[0];
  const team = currentPick ? teamsById[currentPick.teamId] : null;
  const isAutopicking =
    team && Array.isArray(autopicking) && autopicking.includes(team.id);

  const secondsPerPick = boardData?.secondsPerPick;
  const [timeLeft, setTimeLeft] = useState(0);
  const [maxTime, setMaxTime] = useState(1);

  useEffect(() => {
    if (!pickExpiresAt) return;

    function calculateTimeLeft() {
      const now = new Date();
      const diff = pickExpiresAt.getTime() - now.getTime();
      return Math.max(0, diff / 1000);
    }

    const initialTime = calculateTimeLeft();
    setMaxTime(Math.max(secondsPerPick, initialTime));
    setTimeLeft(initialTime);

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 100);

    return () => clearInterval(timer);
  }, [pickExpiresAt, secondsPerPick]);

  function formatTime(seconds) {
    if (secondsPerPick === 0) return "∞";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    if (hours === 0) {
      return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    }
    return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  const percentageLeft =
    draftStatus === "Complete"
      ? 0
      : draftStatus === "InProgress"
        ? secondsPerPick === 0
          ? 100
          : (timeLeft / maxTime) * 100
        : 100;

  function renderTimer() {
    if (timeLeft === 0 && secondsPerPick !== 0) {
      if (boardData?.timeoutAutopick === false) {
        return <span className="text-base">Times Up!</span>;
      }
      return <Spinner />;
    }
    return formatTime(timeLeft);
  }

  function renderTeamLogo() {
    if (team.imgName) {
      return (
        <div className="w-8 h-8 rounded-full shrink-0 overflow-hidden relative">
          <img
            src={team.imgPath}
            alt={team.name}
            className="w-full h-full object-cover"
          />
          {isAutopicking && (
            <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
              <IconAutopicking className="size-4 text-white/90" />
            </div>
          )}
        </div>
      );
    }

    return (
      <div
        className="w-8 h-8 shrink-0 overflow-hidden text-sm flex items-center justify-center text-white text-opacity-90 rounded-full relative"
        style={{ backgroundColor: team.color }}
      >
        {isAutopicking ? (
          <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
            <IconAutopicking className="size-4 text-white/90" />
          </div>
        ) : (
          team.name.charAt(0).toUpperCase()
        )}
      </div>
    );
  }

  return (
    <div
      className={`${onTheClock ? "from-amber-100 to-amber-300 bg-gradient-to-b dark:from-amber-200 dark:to-amber-400" : "dark dark:bg-gradient-to-tl dark:from-gray-800 dark:to-gray-600"} md:min-w-96 relative h-18 flex flex-col`}
    >
      <div className="flex flex-1 items-center p-4 mb-2">
        {draftStatus === "InProgress" ? (
          <>
            <div className="flex flex-col shrink-0 mr-5 w-8">
              <span
                className={`${onTheClock ? "text-gray-900 font-medium dark:text-black" : "text-gray-500 dark:text-gray-400"} text-left uppercase text-xxs mb-px`}
              >
                Pick
              </span>
              <span
                className={`text-lg font-bold ${onTheClock ? "dark:text-gray-900" : "dark:text-gray-200"} text-left leading-none`}
              >
                {currentPick?.overallPick < 10
                  ? ordinal(currentPick?.overallPick)
                  : currentPick.overallPick}
              </span>
            </div>
            <div className="flex items-center justify-center gap-2 flex-1 min-w-0">
              {renderTeamLogo()}
              <div className="min-w-0 flex-1 flex flex-col items-start justify-center truncate">
                <span
                  className={`${onTheClock ? "text-gray-900 font-medium dark:text-black" : "text-gray-500 dark:text-gray-400"} text-xxs`}
                >
                  {onTheClock ? "It's your pick!" : "On the clock"}
                </span>
                <div
                  className={`text-orange-600 ${onTheClock ? "dark:text-orange-600" : "dark:text-gray-200"} leading-tight tracking font-semibold w-full truncate`}
                >
                  {team?.name}
                </div>
              </div>
            </div>
            <div
              className={`text-2xl font-bold ${onTheClock ? "dark:text-black" : "dark:text-gray-200"} text-gray-700 mt-1 shrink-0 w-28 text-right`}
            >
              {renderTimer()}
            </div>
          </>
        ) : draftStatus === "Paused" ? (
          <>
            <div className="flex flex-col shrink-0 mr-5 w-8">
              <span
                className={`${onTheClock ? "text-gray-900 font-medium dark:text-black" : "text-gray-500 dark:text-gray-400"} text-left uppercase text-xxs mb-px`}
              >
                Pick
              </span>
              <span
                className={`text-lg font-bold ${onTheClock ? "dark:text-gray-900" : "dark:text-gray-200"} text-left leading-none`}
              >
                {currentPick?.overallPick < 10
                  ? ordinal(currentPick?.overallPick)
                  : currentPick.overallPick}
              </span>
            </div>
            <div className="flex items-center justify-center gap-2 flex-1 min-w-0">
              {renderTeamLogo()}
              <div className="min-w-0 flex-1 flex flex-col items-start justify-center truncate">
                <span
                  className={`${onTheClock ? "text-gray-900 font-medium dark:text-black" : "text-gray-500 dark:text-gray-400"} text-xxs`}
                >
                  {onTheClock ? "It's your pick!" : "On the clock"}
                </span>
                <div
                  className={`text-orange-600 ${onTheClock ? "dark:text-orange-600" : "dark:text-gray-200"} leading-tight tracking font-semibold w-full truncate`}
                >
                  {team?.name}
                </div>
              </div>
            </div>
            <div
              className={`text-lg leading-tight font-bold ${onTheClock ? "dark:text-black" : "dark:text-gray-200"} text-gray-700 mt-1 shrink-0 w-20 text-right`}
            >
              Paused
            </div>
          </>
        ) : draftStatus.includes("cheduled") ? (
          <>
            <div className="flex flex-col shrink-0 mr-5 w-8">
              <span className="text-gray-500 dark:text-gray-400 text-left uppercase text-xxs mb-px">
                Pick
              </span>
              <span className="text-lg font-bold dark:text-gray-200 text-left leading-none">
                {currentPick?.overallPick < 10
                  ? ordinal(currentPick?.overallPick)
                  : currentPick.overallPick}
              </span>
            </div>
            <div className="flex items-center justify-center gap-2 flex-1 min-w-0">
              {renderTeamLogo()}
              <div className="min-w-0 flex-1 flex flex-col items-start justify-center truncate">
                <span className="text-gray-500 dark:text-gray-400 text-xxs">
                  {draftStatus === "Unscheduled" || !boardData.isAutostart
                    ? "Waiting for commish to start"
                    : `Starts ${new Date(boardData.draftTime).toLocaleString(
                        "en-US",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          month: "short",
                          day: "numeric",
                          hour12: true,
                        },
                      )}`}
                </span>
                <div className="dark:text-gray-200 text-orange-600 leading-tight tracking font-semibold truncate w-full">
                  {team?.name}
                </div>
              </div>
            </div>
            <PreDraftCountdown
              draftTime={boardData.draftTime}
              draftStatus={draftStatus}
            />
          </>
        ) : (
          <>
            <div className="flex flex-col shrink-0 mr-5 w-8">
              <span className="text-gray-500 dark:text-gray-400 text-left uppercase text-xxs mb-px">
                Pick
              </span>
              <span className="text-lg font-bold dark:text-gray-200 text-left leading-none">
                {!currentPick
                  ? "1st"
                  : currentPick.overallPick < 10
                    ? ordinal(currentPick?.overallPick)
                    : currentPick.overallPick}
              </span>
            </div>
            <div className="flex items-center justify-center gap-2 flex-1 min-w-0">
              {leagueData?.imgPath ? (
                <div className="w-8 h-8 rounded-full bg-gray-400 shrink-0 overflow-hidden">
                  <img
                    src={leagueData.imgPath}
                    alt={leagueData.name}
                    className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <div className="w-8 h-8 shrink-0 bg-gray-400 text-sm flex items-center justify-center text-white text-opacity-90 rounded-full">
                  {leagueData?.name.charAt(0).toUpperCase()}
                </div>
              )}
              <div className="min-w-0 flex-1 flex flex-col items-start justify-center">
                <span className="text-gray-500 dark:text-gray-400 text-xxs">
                  {leagueData?.name}
                </span>
                <div className="dark:text-gray-200 text-orange-600 leading-tight tracking font-semibold truncate">
                  Draft complete
                </div>
              </div>
            </div>
            <div className="text-2xl font-bold dark:text-gray-200 text-gray-700 mt-1 shrink-0 w-20 text-right">
              {timeLeft > 0 ? formatTime(timeLeft) : null}
            </div>
          </>
        )}
      </div>
      <div className="h-[3px] absolute bottom-0 dark:bg-gray-700 bg-gray-200 w-full">
        <div
          className={`h-full bg-amber-700 ${onTheClock ? "dark:bg-amber-700" : "dark:bg-amber-500"} ${
            percentageLeft < 100 && percentageLeft > 0
              ? "transition-all duration-100"
              : ""
          }`}
          style={{
            width: `${percentageLeft}%`,
            marginLeft: `${100 - percentageLeft}%`,
          }}
        />
      </div>
    </div>
  );
}

export default OnTheClock;
