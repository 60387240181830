const BASE_URL = "/api/v1/draft";

export const DraftAPI = {
  fetchDraftData: async (boardId) => {
    const response = await fetch(`${BASE_URL}/data/${boardId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const data = await response.json().catch(() => null);
      const errorMessage = data?.error || `HTTP error: ${response.status}`;
      throw new Error(errorMessage);
    }

    const { status, data } = await response.json();

    if (status !== "success") {
      throw new Error("Failed to fetch draft data");
    }

    return data;
  },

  selectPlayer: async (pickId, playerId) => {
    const response = await fetch(`${BASE_URL}/select-player`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pickId,
        playerId,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to select player");
    }

    return response.json();
  },
};
