import useDraftStore from "../../lib/store/DraftStore";
import React from "react";
import { getTeamById } from "../../../global/lib/utils/teams";
import { DraftButton } from "../buttons/DraftButton";

export function PlayerCard({ player }) {
  const { queueIds, setQueueIds, leagueSeasonId } = useDraftStore();
  const team = getTeamById(player.teamId);
  const isQueued = queueIds.includes(player.id);

  const toggleQueue = () => {
    if (isQueued) {
      setQueueIds(queueIds.filter((id) => id !== player.id));
    } else {
      setQueueIds([...queueIds, player.id]);
    }
  };

  return (
    <>
      <div className="h-14 min-w-0 grow md:h-12 py-1.5 leading-tight">
        <div className="flex items-center justify-start">
          <button onClick={toggleQueue} className="mr-4 pointer-events-auto">
            <img
              className="inline-block h-7 w-7"
              src={`/build/images/keeper/icons/star-${isQueued ? "filled" : "outlined"}.png`}
              alt={isQueued ? "Remove from Queue" : "Add to Queue"}
            />
          </button>
          <div className="overflow relative -mb-2.5 mr-2.5 flex h-10 w-8 items-start justify-center overflow-clip dark:brightness-125 dark:saturate-[0.8]">
            <img
              className="w-full"
              src={`/build/images/sport/teams/guernseys/${team.abbr}.png`}
              alt={`${team.name} logo`}
            />
          </div>
          <span className="block min-w-0 flex-1 text-left leading-tight">
            <span className="mr-1 flex items-center justify-start whitespace-nowrap text-md font-medium leading-tight dark:text-gray-100">
              <a
                className="pointer-events-auto truncate sm:hover:underline"
                data-turbo-frame="modal"
                href={`/afl/${leagueSeasonId}/players/profile/${player.id}`}
              >
                {player.abbrName}
              </a>
              <span className="ml-1 text-xs font-medium text-gray-800 dark:text-gray-200">
                {player.positions.join("/")}
              </span>
              {player.injuryStatus !== "0" && (
                <span
                  data-tooltip={player.injuryDesc}
                  className="ml-1.5 inline-flex items-center justify-center rounded-full bg-red-600 px-1 pb-0.75 pt-1 text-xxxxs font-semibold uppercase leading-none tracking-tight text-white dark:bg-red-700"
                >
                  {player.injuryAbbr}
                </span>
              )}
            </span>
            <span className="block min-w-0 flex-1">
              <span className="flex items-center justify-start pb-px pt-1.5 md:truncate md:pt-1">
                <span className="inline-flex items-center justify-center text-xs font-normal leading-none tracking-tight text-gray-700 sm:font-normal dark:text-gray-400">
                  ADP: {player.adp || "-"}
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div className="flex h-12 items-center justify-center md:mr-2 md:-pl-2 md:pr-0">
        <DraftButton playerId={player.id} playerName={player.name} />
      </div>
    </>
  );
}
