import React, { useEffect, useState } from "react";
import useDraftStore from "../lib/store/DraftStore";

export function TeamSelector({ teamsById, picksCount }) {
  const [selectedTeamId, setSelectedTeamId, leagueData] = useDraftStore(
    (state) => [
      state.selectedTeamId,
      state.setSelectedTeamId,
      state.leagueData,
    ],
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = React.useRef(null);
  const selectedTeam = selectedTeamId ? teamsById[selectedTeamId] : null;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="h-14 w-full flex items-center justify-between bg-white border-b dark:border-gray-700 dark:bg-gray-800 dark:text-white px-4 py-2"
      >
        <div className="flex items-center gap-3">
          {selectedTeam ? (
            <>
              <div className="w-8 h-8 rounded-full overflow-hidden bg-gray-800">
                {selectedTeam.imgName ? (
                  <img
                    src={selectedTeam.imgPath}
                    alt={selectedTeam.name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div
                    className="w-full h-full flex items-center justify-center text-white"
                    style={{ backgroundColor: selectedTeam.color }}
                  >
                    {selectedTeam.name.charAt(0)}
                  </div>
                )}
              </div>
              <div className="flex items-start flex-col">
                <span className="font-semibold text-md text-gray-900 dark:text-gray-200 tracking-tight">
                  {selectedTeam.name}
                </span>
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  {picksCount} Picks
                </span>
              </div>
            </>
          ) : (
            <>
              {leagueData?.imgPath ? (
                <div className="w-8 h-8 rounded-full bg-gray-400 shrink-0 overflow-hidden">
                  <img
                    src={leagueData.imgPath}
                    alt={leagueData.name}
                    className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <div className="w-8 h-8 shrink-0 bg-gray-400 text-sm flex items-center justify-center text-white text-opacity-90 rounded-full">
                  {leagueData?.name.charAt(0).toUpperCase()}
                </div>
              )}
              {/*<div className="w-8 h-8 rounded-full overflow-hidden bg-gray-800">*/}
              {/*  {selectedTeam.imgName ? (*/}
              {/*    <img*/}
              {/*      src={selectedTeam.imgPath}*/}
              {/*      alt={selectedTeam.name}*/}
              {/*      className="w-full h-full object-cover"*/}
              {/*    />*/}
              {/*  ) : (*/}
              {/*    <div*/}
              {/*      className="w-full h-full flex items-center justify-center text-white"*/}
              {/*      style={{ backgroundColor: selectedTeam.color }}*/}
              {/*    >*/}
              {/*      {selectedTeam.name.charAt(0)}*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*</div>*/}
              <div className="flex items-start flex-col">
                <span className="font-semibold text-md text-gray-900 dark:text-gray-200 tracking-tight">
                  All Teams
                </span>
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  {picksCount} Picks
                </span>
              </div>
            </>
          )}
        </div>
        <svg
          className={`h-5 w-5 transform transition-transform ${isDropdownOpen ? "rotate-180" : ""}`}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
        </svg>
      </button>

      {isDropdownOpen && (
        <div className="absolute top-full left-0 right-0 bg-gray-50 dark:bg-gray-700 rounded shadow-lg">
          <div className="max-h-72 overflow-y-auto text-md font-medium tracking-tight divide-y dark:divide-gray-700">
            <div className="divide-y dark:divide-gray-600">
              <div
                className={`h-12 cursor-pointer px-4 py-2 ${selectedTeamId === null ? "bg-gray-200 dark:bg-gray-600" : ""} hover:bg-gray-200 dark:hover:bg-gray-600 flex items-center gap-3`}
                onClick={() => {
                  setSelectedTeamId(null);
                  setIsDropdownOpen(false);
                }}
              >
                {leagueData?.imgPath ? (
                  <div className="w-8 h-8 rounded-full bg-gray-400 overflow-hidden">
                    <img
                      src={leagueData.imgPath}
                      alt={leagueData.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <div className="w-8 h-8 shrink-0 bg-gray-400 text-sm flex items-center justify-center text-white text-opacity-90 rounded-full">
                    {leagueData?.name.charAt(0).toUpperCase()}
                  </div>
                )}

                <span className="dark:text-gray-200">All Teams</span>
              </div>
              {Object.values(teamsById).map((team) => (
                <div
                  key={team.id}
                  className={`h-12 cursor-pointer px-4 py-2 ${team.id === selectedTeamId ? "bg-gray-200 dark:bg-gray-600" : ""} hover:bg-gray-200 dark:hover:bg-gray-600 flex items-center gap-3`}
                  onClick={() => {
                    setSelectedTeamId(team.id);
                    setIsDropdownOpen(false);
                  }}
                >
                  <div className="w-8 h-8 rounded-full overflow-hidden bg-gray-800">
                    {team.imgName ? (
                      <img
                        src={team.imgPath}
                        alt={team.name}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div
                        className="w-full h-full flex items-center justify-center text-white"
                        style={{ backgroundColor: team.color }}
                      >
                        {team.name.charAt(0)}
                      </div>
                    )}
                  </div>
                  <span className="dark:text-gray-200">{team.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
